import { autocomplete } from 'getaddress-autocomplete';

const API_KEY = 'G9yvaLCtGUehkfjvHtUUSQ42788'

autocomplete(
    'postcode',
    API_KEY,
    {
        output_fields: {
            formatted_address_0: 'address1',  /* The id of the element bound to 'formatted_address[0]' */
            formatted_address_1: 'address2',  /* The id of the element bound to 'formatted_address[1]' */
            formatted_address_2: 'address3',  /* The id of the element bound to 'formatted_address[2]' */
            formatted_address_3: 'town',  /* The id of the element bound to 'formatted_address[3]' */
            formatted_address_4: 'county',  /* The id of the element bound to 'formatted_address[4]' */
            line_1: 'address1',  /* The id of the element bound to 'line_1' */
            line_2: 'address2',  /* The id of the element bound to 'line_2' */
            line_3: 'address3',  /* The id of the element bound to 'line_3' */
            line_4: 'line_4',  /* The id of the element bound to 'line_4' */
            latitude: 'latitude',  /* The id of the element bound to 'latitude' */
            longitude: 'longitude',  /* The id of the element bound to 'longitude' */
            town_or_city: 'town',  /* The id of the element bound to 'town_or_city' */
            building_number: 'building_number',  /* The id of the element bound to 'building_number' */
            building_name: 'building_name',  /* The id of the element bound to 'building_name' */
            sub_building_number: 'sub_building_number',  /* The id of the element bound to 'sub_building_number' */
            sub_building_name: 'sub_building_name',  /* The id of the element bound to 'sub_building_name' */
            thoroughfare: 'thoroughfare',  /* The id of the element bound to 'thoroughfare' */
            county: 'county',  /* The id of the element bound to 'county' */
            district: 'district',  /* The id of the element bound to 'district' */
            locality: 'address2',  /* The id of the element bound to 'locality' */
            postcode: 'postcode',  /* The id of the element bound to 'postcode' */
            residential: 'residential'
        }
    },
);

function registerAutocompleteForDirector(el: Element, id: string) {
    // If element is already registered, return
    if (el.getAttribute('data-getaddress-autocomplete-registered') === 'true') {
        console.log('already registered');
        return;
    }

    console.log('registering for director', id);

    // add registered data attribute
    el.setAttribute('data-getaddress-autocomplete-registered', 'true');

    const suffix = id.replace('directors_postcode', '');

    console.log('suffix', suffix);

    autocomplete(
        id,
        API_KEY,
        {
            output_fields: {
                formatted_address_0: 'directors_address1' + suffix,
                formatted_address_1: 'directors_address2' + suffix,
                formatted_address_2: 'directors_address3' + suffix,
                formatted_address_3: 'directors_town' + suffix,
                formatted_address_4: 'directors_county' + suffix,
                line_1: 'directors_address1' + suffix,
                line_2: 'directors_address2' + suffix,
                line_3: 'directors_address3' + suffix,
                line_4: 'directors_line_4' + suffix,
                latitude: 'directors_latitude' + suffix,
                longitude: 'directors_longitude' + suffix,
                town_or_city: 'directors_town' + suffix,
                building_number: 'directors_building_number' + suffix,
                building_name: 'directors_building_name' + suffix,
                sub_building_number: 'directors_sub_building_number' + suffix,
                sub_building_name: 'directors_sub_building_name' + suffix,
                thoroughfare: 'directors_thoroughfare' + suffix,
                county: 'directors_county' + suffix,
                district: 'directors_district' + suffix,
                locality: 'directors_address2' + suffix,
                postcode: 'directors_postcode' + suffix,
                residential: 'directors_residential' + suffix,
            }
        },
    );
}

function registerDirectorAutocomplete() {
    console.log('registering directors autocomplete');
    document.querySelectorAll('.director_postcode').forEach((el) => {
        registerAutocompleteForDirector(el, el.id);
    });
}

registerDirectorAutocomplete();

// on directorCountUpdate event, register autocomplete for new directors
window.addEventListener('directorCountUpdate', registerDirectorAutocomplete);

document.addEventListener("getaddress-autocomplete-address-selected", function(e) {
    const countryEl = document.getElementById('countryOfResidence') as HTMLSelectElement;
    let country = e['address']['country'];

    switch(country) {
        case 'England':
        case 'Scotland':
        case 'Wales':
        case 'Northern Ireland':
            country = 'United Kingdom';
            break;
    }
    

    if(countryEl) {
        countryEl.value = country;
    }
});