import Alpine from "alpinejs";
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';

Alpine.data('carousel', () => ({
    swiper: null,
    init() {
        this.swiper = new Swiper(this.$refs.carousel, {
            loop: false,
            slidesPerView: 1,
            spaceBetween: 60,
            breakpoints: {
                // when window width is >= 640px
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 125
                },
            },
            modules: [Navigation, Pagination],
            navigation: {
                disabledClass: 'opacity-50 pointer-events-none',
                hiddenClass: 'hidden',
                nextEl: this.$refs.next,
                prevEl: this.$refs.prev,
            },
            pagination: {
                el: this.$refs.pagination,
                clickable: true,
                bulletClass: 'block transition h-3 w-3 bg-transparent border-2 border-ngc-blue-25 rounded-full hover:bg-ngc-blue-25/50',
                bulletActiveClass: '!bg-ngc-blue-25',
                clickableClass: 'cursor-pointer',
            },
        });
    },
    destroy() {
        this.swiper.destroy();
    },
}));
