import Alpine from "alpinejs";
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';

Alpine.data('quoteCarousel', function() {
    return {
        swiper: null,
        theme: 'multi',
        init() {
            this.theme = this.$refs.quoteCarousel.getAttribute('data-theme');
            const isMultiOrTrust = this.theme === 'multi' || this.theme === 'trust';
            const slidesPerViewDefault = this.theme === 'single' ? 1 : 3;
            const spaceBetweenDefault = this.theme === 'single' ? 60 : 20;
            const breakpoints = isMultiOrTrust ? {
                1024: {
                    slidesPerView: slidesPerViewDefault,
                    spaceBetween: spaceBetweenDefault,
                },
                0: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
            } : {
                1024: {
                    slidesPerView: slidesPerViewDefault,
                    spaceBetween: spaceBetweenDefault,
                },
            };

            this.swiper = new Swiper(this.$refs.quoteCarousel, {
                loop: false,
                slidesPerView: slidesPerViewDefault,
                spaceBetween: spaceBetweenDefault,
                modules: [Navigation, Pagination],
                navigation: {
                    disabledClass: 'opacity-50 pointer-events-none',
                    hiddenClass: 'hidden',
                    nextEl: this.$refs.next,
                    prevEl: this.$refs.prev,
                },
                pagination: {
                    el: this.$refs.pagination,
                    clickable: true,
                    bulletClass: 'block transition h-3 w-3 bg-transparent border-2 border-ngc-blue-25 rounded-full hover:bg-ngc-blue-25/50',
                    bulletActiveClass: '!bg-ngc-blue-25',
                    clickableClass: 'cursor-pointer',
                },
                breakpoints: breakpoints,
                on: {
                    init: () => {
                        this.adjustSlidesForTheme();
                    },
                    resize: () => {
                        this.adjustSlidesForTheme();
                    },
                    slideChange: () => {
                        this.adjustSlidesForTheme();
                    }
                },
            });
        },
        destroy() {
            if (this.swiper !== null) {
                this.swiper.destroy();
            }
        },
        adjustSlidesForTheme() {
            if (!this.swiper || (this.theme !== 'multi' && this.theme !== 'trust')) return;
            
            this.swiper.slides.forEach((slide) => {
                slide.style.opacity = 1;
                slide.classList.remove("enlarge");
                slide.style.height = 'auto';
            });
            
            let maxHeight = this.calculateMaxSlideHeight();
            this.applySlideHeights(maxHeight);
        },
        calculateMaxSlideHeight() {
            let maxHeight = 0;
            this.swiper.slides.forEach((slide) => {
                maxHeight = Math.max(maxHeight, slide.offsetHeight);
            });
            return maxHeight;
        },
        applySlideHeights(maxHeight) {
            const {activeIndex, slides, params} = this.swiper;
            const perView = parseInt(params.slidesPerView, 10);
        
            slides.forEach((slide, index) => {
                slide.style.height = `${maxHeight}px`;
                slide.classList.remove("enlarge");
                const isInActiveSet = index >= activeIndex && index < activeIndex + perView;
                slide.style.opacity = isInActiveSet ? '1' : '0';
                
                if (isInActiveSet && perView === 3 && index === activeIndex + Math.floor(perView / 2)) {
                    slide.classList.add("enlarge");
                    slide.style.height = `${maxHeight + 60}px`;
                }
            });
        }             
    }
});

if (!window.AlpineInitialized) {
    if (window.Alpine) {
        window.Alpine = Alpine;
        Alpine.start();
        
        window.AlpineInitialized = true;
    }
}
