import "../css/main.css";
import Alpine from 'alpinejs';
import anchor from '@alpinejs/anchor';
import collapse from '@alpinejs/collapse';
import Tooltip from "@ryangjchandler/alpine-tooltip";
import "./_carousel";
import "./_quote-carousel";
import "./_interactive-map";
import "./_postcode_lookup";

window.Alpine = Alpine;
Alpine.plugin(anchor);
Alpine.plugin(collapse);
Alpine.plugin(Tooltip);

Alpine.start();
